export const sliceText = (text, maxChars) => maxChars ? text.slice(0, maxChars) : text;
export const shortenText = (text, maxChars) => {
  const newText = sliceText(text, maxChars);
  if (text.length > newText.length) {
    return `${newText.trim()}...`;
  }
  return newText;
};
export const leadOrDescription = (element, maxChars) => {
  const text = (element?.lead || element?.description) ?? '';
  return sliceText(text, maxChars);
};
export const descriptionOrLead = (element, maxChars) => {
  const text = (element?.description || element?.lead) ?? '';
  return sliceText(text, maxChars);
};
export const trimNewLines = value => value?.replace(/(\r\n|\n|\r)/gm, '') ?? '';
export const capitalizeFirstLetter = value => value.charAt(0).toUpperCase() + value.slice(1);